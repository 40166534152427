import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import AccountSettings from "./accountSettings/Index";
import MultiFactorAuthMethods from "./multiFactorAuthentication/Index"
import VerificationDocs from "./verificationDocs/Index"
import MyCharityNGO from "./myCharityNGO/Index"
import MyTransactions from "./myTransactions/Index";
import SalesAgent from "./salesAgent/Index";
import BankTransferRecipients from "./BankTransferRecipients";
import MyBankCards from "./MyBankCards";
import SelfHelp from "./SelfHelp";
import ContactUs from "./ContactUs";
import { signOut } from "../../../store/actions/auth";
import atmIcon from "../../images/atm_icon.svg";
import shareIcon from "../../images/share_icon.svg";
import transactionIcon from "../../images/transaction_icon.svg";
import authenticatorOrangeIcon from "../../images/authenticator_icon_orange.svg";
import SecurityQuestions from "./accountSettings/SecurityQuestions";
import { getConfirmAlert } from "../../../utilities";
import BecomeOurAgent from "./salesAgent/BecomeOurAgent";

const Account = ({ history, user, setIsPageLoading, signOut }) => {
  const query = useLocation().search

  const [subSegment] = useState((new URLSearchParams(query).get('q')))
  const [isAccountSettings, setIsAccountSettings] = useState(false);
  const [isVerificationDocsOpen, setIsVerificationDocsOpen] = useState(false);
  const [isWithdrawFunds, setIsWithdrawFunds] = useState(false);
  const [isReferAndEarn, setIsReferAndEarn] = useState(false);
  const [isSalesAgent, setIsSalesAgent] = useState(false);
  const [isBecomeOurAgent, setIsBecomeOurAgent] = useState(false);
  const [isMyCharityNGO, setIsMyCharityNGO] = useState(false);
  const [isMyCard, setIsMyCard] = useState(false);
  const [isSelfHelp, setIsSelfHelp] = useState(false);
  const [isContactUs, setIsContactUs] = useState(false);
  const [isMyTransactionsVisible, setIsMyTransactionsVisible] = useState(false);
  const [is2FactorAuthOptionsVisible, setIs2FactorAuthOptionsVisible] = useState(false);
  const [isSecurityQuestionsVisible, setIsSecurityQuestionsVisible] = useState(false);

  useEffect(() => {
    if (subSegment == "referAndEarn") {
      setIsReferAndEarn(true)
    } else if (subSegment == "accountSettings") {
      setIsAccountSettings(true)
    } else if (subSegment == "transactionHistory") {
      setIsMyTransactionsVisible(true)
    } else if (subSegment == "salesAgent") {
      setIsSalesAgent(true)
    } else if (subSegment == "becomeASalesAgent") {
      setIsBecomeOurAgent(true)
    } else if (subSegment == "charityNGO") {
      setIsMyCharityNGO(true)
    } else if (subSegment == "verificationDocs") {
      setIsVerificationDocsOpen(true)
    }
  }, [])

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="font-size-20 font-size-sm-24 logo-magenta">My Account</h1>
        <h1 className="font-size-14 font-size-sm-20 logo-magenta">
          {user.first_name} {user.last_name}
        </h1>
      </div>

      <div className="d-flex flex-wrap align-items-center justify-content-between margin-top-10 margin-top-sm-14 margin-bottom-10 padding-4 padding-sm-10 padding-left-10 padding-left-sm-24 padding-right-10 padding-right-sm-24 stretch-full bg-orange border-radius-6">
        <div className="d-flex col-sm-7 align-items-center">
          <img
            className="col-sm-4 fixed-width-60 margin-right-10 margin-top-8 margin-bottom-8"
            src={authenticatorOrangeIcon}
          />
          {user.enabled_multi_factor_authentications.authenticator_enabled ||
            user.enabled_multi_factor_authentications.phone_otp_enabled ?
            <div className="col-sm-8 margin-top-8 margin-bottom-8 font-size-14 font-size-sm-16 white margin-right-10">
              Multi-Factor Authentication is enabled
            </div> :
            <div className="col-sm-8 margin-top-8 margin-bottom-8 font-size-14 font-size-sm-16 white margin-right-10">
              Make your account more secure with MFA
            </div>}
        </div>

        {user.enabled_multi_factor_authentications.authenticator_enabled ||
          user.enabled_multi_factor_authentications.phone_otp_enabled ?
          <div className="max-width-250 stretch-full-sm">
            <button
              className="btn stretch-full margin-top-4 margin-top-sm-8 margin-bottom-8 padding-10 font-size-14 font-size-sm-16 bg-white orange"
              type="button"
              onClick={() => {
                setIs2FactorAuthOptionsVisible(true)
              }}>
              Manage MFA
            </button>
          </div>
          :
          <div className="fixed-width-280 stretch-full-sm">
            <button
              className="btn stretch-full padding-10 margin-top-4 margin-top-sm-8 margin-bottom-8 font-size-14 font-size-sm-16 bg-white orange"
              type="button"
              onClick={() => {
                if (user.security_questions_answered == true) {
                  setIs2FactorAuthOptionsVisible(true)
                } else {
                  setIsSecurityQuestionsVisible(true)
                }
              }}>
              Set Up Multi-Factor Authentication
            </button>
          </div>
        }
      </div>

      <div className="row font-size-14 font-size-sm-16 padding-bottom-20">
        <div className="col-sm-6">
          <div className="max-width-320 stretch-full-sm margin-right-0 margin-right-sm-10">
            <div className="padding-top-10 padding-top-sm-36 padding-bottom-10 padding-bottom-sm-36">
              <div
                className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full logo-magenta padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
                onClick={() => setIsAccountSettings(true)}
              >
                <i className="far fa-user logo-magenta font-size-19 margin-right-20 margin-right-sm-24"></i>
                <label className="cursor-pointer">My Account Settings</label>
              </div>
            </div>

            <div className="padding-top-10 padding-top-sm-36 padding-bottom-10 padding-bottom-sm-36">
              <div
                className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full logo-magenta padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
                onClick={() => setIsVerificationDocsOpen(true)}
              >
                <i className="far fa-file-alt logo-magenta font-size-19 margin-right-20 margin-right-sm-24"></i>
                <label className="cursor-pointer">Verification Documents</label>
              </div>
            </div>

            <div className="padding-top-10 padding-top-sm-36 padding-bottom-10 padding-bottom-sm-36">
              <div
                className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full logo-magenta padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
                onClick={() => {
                  setIsMyCharityNGO(true)
                }}
              >
                <img
                  className="fixed-width-20 margin-right-20 margin-right-sm-24"
                  src={shareIcon}
                />
                <label className="cursor-pointer">My Charity NGO</label>
              </div>
            </div>

            <div className="padding-top-10 padding-top-sm-36 padding-bottom-10 padding-bottom-sm-36">
              <div
                className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full logo-magenta padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
                onClick={() => setIsWithdrawFunds(true)}
              >
                <img
                  className="fixed-width-20 margin-right-20 margin-right-sm-24"
                  src={atmIcon}
                />
                <label className="cursor-pointer">Bank Transfer Recipients</label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="max-width-320 stretch-full-sm margin-left-0 margin-left-sm-10">
            <div className="padding-top-10 padding-top-sm-36 padding-bottom-10 padding-bottom-sm-36">
              <div
                className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full logo-magenta padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
                onClick={() => setIsMyCard(true)}
              >
                <i className="fa fa-credit-card font-size-18 margin-right-20 margin-right-sm-24"></i>
                <label className="cursor-pointer">My Bank Cards</label>
              </div>
            </div>
            <div className="padding-top-10 padding-top-sm-36 padding-bottom-10 padding-bottom-sm-36">
              <div
                className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full logo-magenta padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
                onClick={() => setIsMyTransactionsVisible(true)}
              >
                <img
                  className="fixed-width-20 margin-right-20 margin-right-sm-24"
                  src={transactionIcon}
                />
                <label className="cursor-pointer">My Transactions</label>
              </div>
            </div>
            {/* 
            {
              user.is_agent ?
                <div className="padding-top-10 padding-top-sm-36 padding-bottom-10 padding-bottom-sm-36">
                  <div
                    className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full logo-magenta padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
                    onClick={() => {
                      setIsSalesAgent(true)
                    }}
                  >
                    <img
                      className="fixed-width-20 margin-right-20 margin-right-sm-24"
                      src={shareIcon}
                    />
                    <label className="cursor-pointer">Sales Agent</label>
                  </div>
                </div> :
                <div>
                  {user.accepting_new_agent &&
                    <div className="padding-top-10 padding-top-sm-36 padding-bottom-10 padding-bottom-sm-36">
                      <div
                        className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full logo-magenta padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
                        onClick={() => {
                          setIsBecomeOurAgent(true)
                        }}
                      >
                        <img
                          className="fixed-width-20 margin-right-20 margin-right-sm-24"
                          src={shareIcon}
                        />
                        <label className="cursor-pointer">Become a Sales Agent</label>
                      </div>
                    </div>
                  }
                </div>
            } */}

            <div className="padding-top-10 padding-top-sm-36 padding-bottom-10 padding-bottom-sm-36">
              <div
                className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full red padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
                onClick={() => {
                  getConfirmAlert({
                    title: "Confirm!",
                    message: "Are you sure you want to logout?",
                    onClickYes: () => {
                      signOut()
                    },
                    onClickNo: () => null
                  })
                }}
              >
                <i className="fas fa-sign-out-alt opacity-75 font-size-20 margin-right-20 margin-right-sm-22"></i>
                <label className="cursor-pointer">Logout</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isAccountSettings}
        onRequestClose={() => {
          setIsAccountSettings(false)
          history.push("/dashboard/account")
        }}
        className="modal max-width-420 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-sm-40 padding-right-sm-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-sm-22 logo-magenta">
            Account Settings
          </h1>
          <i
            onClick={() => {
              setIsAccountSettings(false)
              history.push("/dashboard/account")
            }}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="overflow-scroll-y padding-10 padding-bottom-sm-30 padding-left-sm-40 padding-right-sm-40">
          <AccountSettings setIsPageLoading={setIsPageLoading} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isVerificationDocsOpen}
        onRequestClose={() => {
          setIsVerificationDocsOpen(false)
          history.push("/dashboard/account")
        }}
        className="modal max-width-420 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-sm-40 padding-right-sm-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-sm-22 logo-magenta">
            Verification Documents
          </h1>
          <i
            onClick={() => {
              setIsVerificationDocsOpen(false)
              history.push("/dashboard/account")
            }}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="overflow-scroll-y padding-10 padding-bottom-sm-30 padding-left-sm-40 padding-right-sm-40">
          <VerificationDocs setIsPageLoading={setIsPageLoading} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isWithdrawFunds}
        onRequestClose={() => setIsWithdrawFunds(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-sm-40 padding-right-sm-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-sm-22 logo-magenta">
            Bank Transfer Recipients
          </h1>
          <i
            onClick={() => setIsWithdrawFunds(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="overflow-scroll-y padding-left-10 padding-right-10 padding-left-md-40 padding-right-md-40"
          style={{ height: "80vh" }}
        >
          <BankTransferRecipients setIsPageLoading={setIsPageLoading} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isMyCard}
        onRequestClose={() => setIsMyCard(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-sm-40 padding-right-sm-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-sm-22 logo-magenta">
            My Bank Cards
          </h1>
          <i
            onClick={() => setIsMyCard(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-10 padding-top-sm-20 padding-bottom-sm-20 padding-left-sm-40 padding-right-sm-40">
          <MyBankCards setIsPageLoading={setIsPageLoading} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isSelfHelp}
        onRequestClose={() => setIsSelfHelp(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-sm-40 padding-right-sm-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-sm-22 logo-magenta">Self Help</h1>
          <i
            onClick={() => setIsSelfHelp(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-10 padding-top-sm-20 padding-bottom-sm-20 padding-left-sm-40 padding-right-sm-40">
          <SelfHelp setIsPageLoading={setIsPageLoading} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isContactUs}
        onRequestClose={() => setIsContactUs(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div style={{ position: "relative" }}>
          <div
            className="close-modal-icon"
            onClick={() => setIsContactUs(false)}
          >
            <i className="fa fa-close"></i>
          </div>
          <ContactUs setIsPageLoading={setIsPageLoading} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isSalesAgent}
        onRequestClose={() => {
          history.push("/dashboard/account")
          setIsSalesAgent(false)
        }}
        className="modal max-width-420 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-sm-40 padding-right-sm-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-sm-22 logo-magenta">
            Sales Agent
          </h1>
          <i
            onClick={() => {
              history.push("/dashboard/account")
              setIsSalesAgent(false)
            }}

            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="overflow-scroll-y padding-10 padding-bottom-sm-30 padding-left-sm-40 padding-right-sm-40">
          <SalesAgent setIsPageLoading={setIsPageLoading} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isBecomeOurAgent}
        onRequestClose={() => {
          history.push("/dashboard/account")
          setIsBecomeOurAgent(false)
        }}
        className="modal max-width-700 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-sm-40 padding-right-sm-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-sm-22 logo-magenta">
            Become Our Agent
          </h1>
          <i
            onClick={() => {
              history.push("/dashboard/account")
              setIsBecomeOurAgent(false)
            }}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="overflow-scroll-y padding-10 padding-bottom-sm-30 padding-left-sm-40 padding-right-sm-40">
          <BecomeOurAgent
            setIsPageLoading={setIsPageLoading}
            handleClose={() => {
              history.push("/dashboard/account")
              setIsBecomeOurAgent(false)
            }}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isMyCharityNGO}
        onRequestClose={() => {
          history.push("/dashboard/account")
          setIsMyCharityNGO(false)
        }}
        className="modal max-width-700 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-sm-40 padding-right-sm-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-sm-22 logo-magenta">
            My Charity NGO
          </h1>
          <i
            onClick={() => {
              history.push("/dashboard/account")
              setIsMyCharityNGO(false)
            }}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="overflow-scroll-y padding-10 padding-bottom-sm-30 padding-left-sm-40 padding-right-sm-40">
          <MyCharityNGO
            history={history}
            setIsPageLoading={setIsPageLoading}
            handleClose={() => {
              history.push("/dashboard/account")
              // setIsMyCharityNGO(false)
            }}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isMyTransactionsVisible}
        onRequestClose={() => {
          history.push("/dashboard/account")
          setIsMyTransactionsVisible(false)
        }}
        className="modal max-width-420 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-sm-40 padding-right-sm-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-sm-22 logo-magenta">
            My Transactions
          </h1>
          <i
            onClick={() => {
              history.push("/dashboard/account")
              setIsMyTransactionsVisible(false)
            }}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="overflow-scroll-y padding-10 padding-top-sm-20 padding-bottom-sm-30 padding-left-sm-40 padding-right-sm-40">
          <MyTransactions setIsPageLoading={setIsPageLoading} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={is2FactorAuthOptionsVisible}
        onRequestClose={() => setIs2FactorAuthOptionsVisible(false)}
        className="modal max-width-760 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Set Up 2-Factor Authentication
          </h1>
          <i
            onClick={() => setIs2FactorAuthOptionsVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line" />

        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-sm-20 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40"
        >
          <MultiFactorAuthMethods
            setIsPageLoading={setIsPageLoading}
            handleClose={() => setIs2FactorAuthOptionsVisible(false)}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isSecurityQuestionsVisible}
        onRequestClose={() => setIsSecurityQuestionsVisible(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <SecurityQuestions
          setIsPageLoading={setIsPageLoading}
          handleClose={() => setIsSecurityQuestionsVisible(false)}
          onSaveSuccessful={() => {
            setIsSecurityQuestionsVisible(false)
            setIs2FactorAuthOptionsVisible(true)
          }}
        />
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
});

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
